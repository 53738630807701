<template>
    <div style="margin: 0;text-align: center;" class="login-page" >
      <video autoplay loop muted  playsinline class="background-video">
        <source src="../video/login.mp4" type="video/mp4" >
      </video>
      <el-form
                :rules="rules"
                ref="loginForm"
                v-loading="loading"
                element-loading-text="正在登录..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 0.4)"
                :model="loginForm"
                class="loginContainer"
                style="background: rgba(255,255,255,0.1)"
      >
            <div class="loginTitle">系统登录</div>
            <el-form-item prop="phone">
                <el-input size="normal" type="text" v-model="loginForm.phone" auto-complete="off"
                          placeholder="请输入手机号"  class="my-custom-input"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input size="normal" type="password" v-model="loginForm.password" auto-complete="off"
                          placeholder="请输入密码" @keydown.enter.native="submitLogin" class="my-custom-input"></el-input>
            </el-form-item>
            <el-form-item prop="code">
                <el-input size="normal" type="text" v-model="loginForm.code" auto-complete="off"
                          placeholder="点击图片更换验证码" @keydown.enter.native="submitLogin"  class="my-custom-input"></el-input>
                <img :src="vcUrl" @click="updateVerifyCode" alt="" style="cursor: pointer">
            </el-form-item>
<!--            <el-checkbox size="normal" class="loginRemember" v-model="checked"></el-checkbox>-->
            <el-button size="normal" type="primary" style="width: 100%;" @click="submitLogin">登录</el-button>
        </el-form>
      <div style="position: absolute; bottom: 0; width: 100%;margin-bottom: 30px">
        <a style="color: white" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2024055577号-2</a>
      </div>
    </div>

</template>
<script>
    import {initMenu} from "@/utils/menus";
    import router from "@/router";
    import store from "@/store";
    import bcrypt from 'bcryptjs';

    export default {
        name: "Login",
        data() {
            return {
                loading: false,
                vcUrl: '/api/verifyCode?time='+new Date(),
                loginForm: {
                    phone: '',
                    password: '',
                     code:''
                },
              bcryptLoginForm: {
                phone: '',
                password: '',
                code:''
              },
                checked: true,
                rules: {
                    phone: [{required: true, message: '请输入手机号', trigger: 'blur'}],
                    password: [{required: true, message: '请输入密码', trigger: 'blur'}],
                    code: [{required: true, message: '请输入验证码', trigger: 'blur'}]
                }
            }
        },
        methods: {
            updateVerifyCode() {
                this.vcUrl = '/api/verifyCode?time='+new Date();
            },
            submitLogin() {
                this.$refs.loginForm.validate((valid) => {
                    if (valid) {
                      const salt = "$2a$10$7jnRAeV2e.W.nnWzukoQge"; // 生成一个 salt，通常这是一个异步操作，但为了简单起见，这里使用同步方法
                      this.hashedPassword = bcrypt.hashSync(this.loginForm.password, salt); // 使用 salt 和密码生成哈希值
                      this.bcryptLoginForm.password=this.hashedPassword;
                      this.bcryptLoginForm.phone=this.loginForm.phone;
                      this.bcryptLoginForm.code=this.loginForm.code;
                      // console.log(this.hashedPassword)
                        this.loading = true;
                        this.postRequest('/doLogin', this.bcryptLoginForm).then(resp => {
                            this.loading = false;
                            if (resp) {
                              console.log(resp);
                              const authHeader = resp.obj.token;
                              window.sessionStorage.setItem('token', authHeader);
                                 this.$store.commit('INIT_CURRENTHR', resp.obj);
                                window.sessionStorage.setItem("user", JSON.stringify(resp.obj));
                                let path = this.$route.query.redirect;
                                this.$router.replace((path == '/' || path == undefined) ? '/home' : path);
                            }else{
                                this.vcUrl = '/api/verifyCode?time='+new Date();
                            }
                        })
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style>
    .loginContainer {
        border-radius: 15px;
        background-clip: padding-box;
        margin: 180px auto;
        width: 350px;
        padding: 15px 35px 15px 35px;
        background: #fff;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 25px #cac6c6;
      position: absolute;
      top: 0px;
      right:100px;
    }

    .loginTitle {
      font-size: 15px;
      font-weight: bold;
        margin: 15px auto 20px auto;
        text-align: center;
        color: white;
    }

    .loginRemember {
        text-align: left;
        margin: 0px 0px 15px 0px;
    }
    .el-form-item__content{
        display: flex;
        align-items: center;
    }
    .login-page {
      margin: 0;
      padding: 0;
      position: relative;
      height: 100vh; /* 使登录页占据整个视口高度 */
      overflow: hidden; /* 防止滚动 */
      //background:url('../assets/back.png');
      //background-size: cover; /* 使背景图片覆盖整个容器 */
    }

    .background-video {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* 保持视频的纵横比并覆盖整个容器 */
      z-index: -1; /* 确保视频在登录表单等其他内容之下 */
    }
    .my-custom-input .el-input__inner {
      background-color: rgba(255, 255, 255, 0.1); /* 设置背景色 */
      color: white;
    }
    @media (max-width:768px) {
      .login-page {
        margin: 0;
        padding: 0;
        position: relative;
        height: 100vh; /* 使登录页占据整个视口高度 */
        overflow: hidden; /* 防止滚动 */
      }
      .background-video{
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* 保持视频的纵横比并覆盖整个容器 */
        z-index: -1; /* 确保视频在登录表单等其他内容之下 */
      }
      .loginContainer {
        border-radius: 15px;
        background-clip: padding-box;
        margin: 180px auto;
        width: 260px;
        padding: 15px 35px 15px 35px;
        background: #fff;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 25px #cac6c6;
        position: absolute;
        top: 0px;
        right:10%;
      }
      .my-custom-input{
        width: 150px;
      }
    }
</style>
