import {getRequest} from "./api";

export const initMenu = (router, store) => {
    if (store.state.routes.length > 0) {
        return;
    }
    getRequest("/system/config/menu").then(data => {
        if (data) {
            let fmtRoutes = formatRoutes(data);
            router.addRoutes(fmtRoutes);
            store.commit('initRoutes', fmtRoutes);
            store.dispatch('connect');
        }
    })
}
export const formatRoutes = (routes) => {
    let fmRoutes = [];
    routes.forEach(router => {
        let {
            path,
            component,
            name,
            meta,
            iconCls,
            children
        } = router;
        if (children && children instanceof Array) {
            children = formatRoutes(children);
        }
        let fmRouter = {
            path: path,
            name: name,
            iconCls: iconCls,
            meta: meta,
            children: children,
            component(resolve) {
                if (component.startsWith("Home")) {
                    require(['../views/' + component + '.vue'], resolve);
                } else if (component.startsWith("User")) {
                    require(['../views/user/' + component + '.vue'], resolve);
                } else if (component.startsWith("Supplier")) {
                    require(['../views/supplier/' + component + '.vue'], resolve);
                } else if (component.startsWith("Product")) {
                    require(['../views/product/' + component + '.vue'], resolve);
                } else if (component.startsWith("Vendor")) {
                    require(['../views/vendor/' + component + '.vue'], resolve);
                } else if (component.startsWith("Tag")) {
                    require(['../views/tag/' + component + '.vue'], resolve);
                } else if (component.startsWith("Stock")) {
                    require(['../views/stock/' + component + '.vue'], resolve);
                } else if (component.startsWith("Order")) {
                    require(['../views/order/' + component + '.vue'], resolve);
                } else if (component.startsWith("Exception")) {
                    require(['../views/exception/' + component + '.vue'], resolve);
                } else if (component.startsWith("Panda")) {
                    require(['../views/panda/' + component + '.vue'], resolve);
                } else if (component.startsWith("System")) {
                    require(['../views/system/' + component + '.vue'], resolve);
                } else if (component.startsWith("Echarts")) {
                    require(['../views/echarts/' + component + '.vue'], resolve);
                }else if (component.startsWith("Power")) {
                    require(['../views/power/' + component + '.vue'], resolve);
                }else if (component.startsWith("WorkOrder")) {
                    require(['../views/work/' + component + '.vue'], resolve);
                }

            }
        }
        fmRoutes.push(fmRouter);
    })
    return fmRoutes;
}